

 export default function Footer(){
 	return <footer className="footer">
 		<div class="container">
 			© {new Date().getFullYear()}
	        <span> DerfiDefi. All rights reserved.</span>
	    </div>
	</footer>
}
