import { useState, useEffect } from 'react';

export default function ImpermanentLoss(){

	let [ helpToggle, setHelpToggle ] = useState(false)
	let [ token1Name, setToken1Name ] = useState('')
	let [ token2Name, setToken2Name ] = useState('')
	let [ token1Amount, setToken1Amount ] = useState(0)
	let [ token2Amount, setToken2Amount ] = useState(0)

	let [ sliderAmount, setSliderAmount ] = useState(100)
	let [ feesPercent, setFeesPercent ] = useState(0)

	return <div className="container impermanent">

		<h1 className="is-size-3 mb-4">Impermanent Loss Calculator<span className="help-toggle ml-2 has-text-warning" onClick={() => setHelpToggle(!helpToggle)}>help {helpToggle ? '▼' : '▲'}</span></h1>

		{helpToggle && <div className="help-content mb-4 has-text-warning">
			<p className="mb-3">
				This tool is a calculator that looks at how impermanent loss and fees can affect Liquidity Pool deposits. Name your tokens and enter a starting value for them. This represents the amount of tokens initially deposited into a Liquidity Pool. At the time of deposit, it is assumed that the amounts will equal each other in value.</p>
			<p className="mb-3">
				After the initial conditions are set, adjust the token values relative to each other and the fees generated by the pool to see if the end result leads to a loss or a gain. The relative value slider starts at 100%. Lowering it to 50% is equivalent to increasing it to 200% since impermant loss results from relative changes in value in either direction. 
			</p>
			<p className="mb-3">
				<a href="https://pintail.medium.com/uniswap-a-good-deal-for-liquidity-providers-104c0b6816f2" target="_blank" className="light-blue">Here</a> is a good article on it. It is worth following along with the example by entering the values into this calculator for learning. 
			</p>
			<p className="mb-3">
				Keep the fees at 0 to see only the impermant loss.
			</p>
			<p className="mb-3">
				The combined value fields show the value if one token is sold for the other. At the time of deposit it is assumed that the values equal each other so if one token is sold for the other then the combined value is twice the token value sold to. ie. if the initial amounts are 1 Eth and 100 Dai then the combined value in Eth is 2 Eth since selling 100 Dai will get you 1 Eth. It is a way of representing the total value of the tokens combined and can be a useful way to compare them. The final combined value if the tokens are kept out of the pools relies on the final price change of the tokens since their values can vary and will likely not equal eachother.
			</p>
			
		</div>}

		<span>Name your tokens and set a starting amount for the pool</span>
		<div className="field is-grouped is-grouped-multiline">
			<div className="control">
				<input 
					className="input is-primary" 
					type="text" 
					placeholder="Token 1 Name" 
					value={token1Name} 
					onChange={e => setToken1Name(e.target.value)} />
			</div>
			<div className="control">
				<input 
					className="input is-primary" 
					type="number" 
					placeholder="Token 1 Amount" 
					value={token1Amount} 
					onChange={e => setToken1Amount(parseFloat(e.target.value))} />
			</div>
		</div>

		<div className="field is-grouped is-grouped-multiline">
			<div className="control">
				<input 
					className="input is-primary" 
					type="text" 
					placeholder="Token 2 Name" 
					value={token2Name} 
					onChange={e => setToken2Name(e.target.value)} />
			</div>
			<div className="control">
				<input 
					className="input is-primary" 
					type="number" 
					placeholder="Token 2 Amount" 
					value={token2Amount} 
					onChange={e => setToken2Amount(parseFloat(e.target.value))} />
			</div>
		</div>
		<div className="red mb-4">{!entered() && 'Values cannot be 0'}</div>

		<div className="is-size-5 mb-6">
			<div className="factory-list mb-2">
				<span className="factory-list-name">Constant Product</span>
				<span className="factory-list-amount has-text-info">{entered() && format(constantProduct())}</span>
			</div>

			<div className="factory-list">
				<span className="factory-list-name">One {token1Name ? token1Name : 'Token 1'} price</span>
				<span className="factory-list-amount has-text-info">{entered() && `${format(token2Amount / token1Amount)} ${token2Name ? token2Name : 'Token 2'}`}</span>
			</div>
			<div className="factory-list mb-2">
				<span className="factory-list-name">One {token2Name ? token2Name : 'Token 2'} price</span>
				<span className="factory-list-amount has-text-info">{entered() && `${format(token1Amount / token2Amount)} ${token1Name ? token1Name : 'Token 1'}`}</span>
			</div>

			<div className="factory-list">
				<span className="factory-list-name">Initial combined value in {token1Name ? token1Name : 'Token 1'}</span>
				<span className="factory-list-amount has-text-info">{entered() && token1Amount * 2}</span>
			</div>
			<div className="factory-list mb-2">
				<span className="factory-list-name">Initial combined value in {token2Name ? token2Name : 'Token 2'}</span>
				<span className="factory-list-amount has-text-info">{entered() && token2Amount * 2}</span>
			</div>
		</div>

		<div className="mb-1">Set the percentage change of the tokens in relation to eachother. 100% is unchanged.</div>
		<div className="field has-addons">
			<input 
				type="range" 
				min="0" 
				max="1000" 
				value={sliderAmount} 
				className="slider mr-4" 
				onChange={e => setSliderAmount(e.target.value)} />

			<div className="control">
				<input 
					className="input number-input is-primary" 
					type="number" 
					placeholder="Pool change" 
					value={sliderAmount} 
					onChange={e => setSliderAmount(e.target.value)} />
			</div>
			
		    <div className="control">
			    <a className="button is-primary">
			    	%
			    </a>
			</div>
		</div>

		<div className="mb-1">Set the percentage increase of the pool due to fees. 0% is no fees.</div>
		<div className="field has-addons mb-4">
			<div className="control">
				<input 
					className="input number-input is-primary" 
					type="number" 
					placeholder="Pool change" 
					value={feesPercent} 
					onChange={e => setFeesPercent(parseFloat(e.target.value))} />
			</div>
			
		    <div className="control">
			    <a className="button is-primary">
			    	%
			    </a>
			</div>
		</div>

		<div className="is-size-5 mb-4">
			<div className="factory-list">
				<span className="factory-list-name">One {token1Name ? token1Name : 'Token 1'} price</span>
				<span className="factory-list-amount has-text-info">{entered() && `${format(newToken1Price())} ${token2Name ? token2Name : 'Token 2'}`}</span>
			</div>
			<div className="factory-list mb-2">
				<span className="factory-list-name">One {token2Name ? token2Name : 'Token 2'} price</span>
				<span className="factory-list-amount has-text-info">{entered() && `${format(newToken2Price())} ${token1Name ? token1Name : 'Token 1'}`}</span>
			</div>
		</div>

		<div className="is-size-4 mb-3 light-green">
 			If your tokens were kept <b>in</b> the pool:
 		</div>
 		<div className="is-size-5 mb-4">
			<div className="factory-list">
				<span className="factory-list-name">{token1Name ? token1Name : 'Token 1'} amount</span>
				<span className="factory-list-amount has-text-info">{format(newToken1Amount())}</span>
			</div>
			<div className="factory-list mb-2">
				<span className="factory-list-name">{token2Name ? token2Name : 'Token 2'} amount</span>
				<span className="factory-list-amount has-text-info">{format(newToken2Amount())}</span>
			</div>

			<div className="factory-list">
				<span className="factory-list-name light-red">Final combined value in {token1Name ? token1Name : 'Token 1'}</span>
				<span className="factory-list-amount has-text-info">{format(finalCombinedToken1AmountIn())}</span>
			</div>
			<div className="factory-list mb-2">
				<span className="factory-list-name light-red">Final combined value in {token2Name ? token2Name : 'Token 2'}</span>
				<span className="factory-list-amount has-text-info">{format(finalCombinedToken2AmountIn())}</span>
			</div>

			<div className="factory-list mb-2">
				<span className="factory-list-name">Gain / Loss</span>
				<span className="factory-list-amount has-text-info">{gain()}</span>
			</div>
		</div>

		<div className="is-size-4 mb-3 light-green">
 			If your tokens were kept <b>out</b> of the pool:
 		</div>
		<div className="is-size-5 mb-4">
			<div className="factory-list">
				<span className="factory-list-name">{token1Name ? token1Name : 'Token 1'} amount</span>
				<span className="factory-list-amount has-text-info">{entered() && token1Amount}</span>
			</div>
			<div className="factory-list mb-2">
				<span className="factory-list-name">{token2Name ? token2Name : 'Token 2'} amount</span>
				<span className="factory-list-amount has-text-info">{entered() && token2Amount}</span>
			</div>

			<div className="factory-list">
				<span className="factory-list-name light-red">Final combined value in {token1Name ? token1Name : 'Token 1'}</span>
				<span className="factory-list-amount has-text-info">{format(finalCombinedToken1AmountOut())}</span>
			</div>
			<div className="factory-list mb-2">
				<span className="factory-list-name light-red">Final combined value in {token2Name ? token2Name : 'Token 2'}</span>
				<span className="factory-list-amount has-text-info">{format(finalCombinedToken2AmountOut())}</span>
			</div>
		</div>
	</div>

	function feesMultiplier(){
		if (!feesPercent)
			return 1
		return (100 + feesPercent) / 100
	}

	function constantProduct(){
		return token1Amount * token2Amount 
	}

	function newToken1Price(){
		return (token2Amount * (sliderAmount / 100)) / token1Amount
	}

	function newToken2Price(){
		return token1Amount / (token2Amount * (sliderAmount / 100))
	}

	function newToken1Amount(){
		return Math.sqrt(constantProduct() / newToken1Price()) * feesMultiplier()
	}

	function newToken2Amount(){
		return Math.sqrt(constantProduct() / newToken2Price()) * feesMultiplier()
	}


	function finalCombinedToken1AmountIn(){
		return newToken1Amount() + (newToken2Amount() * newToken2Price())
	}

	function finalCombinedToken2AmountIn(){
		return newToken2Amount() + (newToken1Amount() * newToken1Price())
	}

	function finalCombinedToken1AmountOut(){
		return token1Amount + (token2Amount * newToken2Price())
	}

	function finalCombinedToken2AmountOut(){
		return token2Amount + (token1Amount * newToken1Price())
	}

	function gain(){
		let gain = (finalCombinedToken1AmountIn() - finalCombinedToken1AmountOut()) / finalCombinedToken1AmountOut() * 100
		if (isNaN(gain))
			return ''
		
		return <span className={gain < 0 ? 'red' : 'green'}>{gain.toFixed(2)}%</span>
	}

	




	// eth_liquidity_pool * token_liquidity_pool = constant_product

	// eth_price = token_liquidity_pool / eth_liquidity_pool
	
	// eth_liquidity_pool = sqrt(constant_product / eth_price)

	// token_liquidity_pool = sqrt(constant_product * eth_price)

	// divergence_loss = 2 * sqrt(price_ratio) / (1+price_ratio) — 1

	function entered(){
		return (token1Amount !== 0 && !isNaN(token1Amount)) && (token2Amount !== 0 && !isNaN(token2Amount))
	}

	function format(val){
		if (!val || isNaN(val))
			return ''

		return precision(val, 4)
	}


	function precision(string, digits) {
		string = string.toString()

	    if (string.includes('.')) {
	        const parts = string.split('.');
	        return parts[0] + '.' + parts[1].slice(0, digits);
	    }
	    return string;
	}
}